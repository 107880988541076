/**
 * v0 by Vercel.
 * @see https://v0.dev/t/4AQFPprtCTI
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"

import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"
import { useState } from "react"

export default function Component() {
    const [sellingPrice, setSellingPrice] = useState(0)
    const [wholesalePrice, setWholesalePrice] = useState(0)
    const [shippingCost, setShippingCost] = useState(0)
    const [currency, setCurrency] = useState("EUR")
    const referralFee = sellingPrice * 0.15
    const shippingFee = shippingCost * 0.15
    const vat = (wholesalePrice + shippingCost) * 0.19
    const totalCost = wholesalePrice + shippingCost + referralFee + shippingFee + vat
    const profit = sellingPrice - totalCost
    const profitMargin = sellingPrice ? (profit / sellingPrice) * 100 : 0
    return (
        <Card className="w-full max-w-xl mx-auto">
            <CardHeader>
                <CardTitle>Amazon Profit Calculator</CardTitle>
                <CardDescription>Calculate your profits from selling on Amazon.</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
                <div className="grid gap-2">
                    <Label htmlFor="sellingPrice">
                        <div className="flex items-center gap-2">
                            <ShoppingBagIcon className="w-4 h-4" />
                            Selling Price (incl. VAT)
                        </div>
                    </Label>
                    <div className="flex items-center">
                        <Input
                            id="sellingPrice"
                            type="number"
                            min={0}
                            step="0.01"
                            value={sellingPrice}
                            onChange={(e) => setSellingPrice(parseFloat(e.target.value))}
                            className="w-full mr-2"
                        />
                        <Select value={currency} onValueChange={setCurrency}>
                            <SelectTrigger>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="EUR">EUR</SelectItem>
                                <SelectItem value="CZK">CZK</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-2">
                    <div className="grid gap-2">
                        <Label htmlFor="wholesalePrice">
                            <div className="flex items-center gap-2">
                                <PackageIcon className="w-4 h-4" />
                                Wholesale Price (excl. VAT)
                            </div>
                        </Label>
                        <div className="flex items-center">
                            <Input
                                id="wholesalePrice"
                                type="number"
                                min={0}
                                step="0.01"
                                value={wholesalePrice}
                                onChange={(e) => setWholesalePrice(parseFloat(e.target.value))}
                                className="w-full"
                            />
                        </div>
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="shippingCost">
                            <div className="flex items-center gap-2">
                                <TruckIcon className="w-4 h-4" />
                                Shipping Cost (FBM)
                            </div>
                        </Label>
                        <div className="flex items-center">
                            <Input
                                id="shippingCost"
                                type="number"
                                min={0}
                                step="0.01"
                                value={shippingCost}
                                onChange={(e) => setShippingCost(parseFloat(e.target.value))}
                                className="w-full"
                            />
                        </div>
                    </div>
                </div>
            </CardContent>
            <CardContent className="grid gap-4">
                <div className="flex items-center gap-2">
                    <PercentIcon className="w-4 h-4" />
                    <span>Amazon Referral Fee (15%)</span>
                    <span className="ml-auto font-semibold">
                        {currency} {referralFee.toFixed(2)}
                    </span>
                </div>
                <div className="flex items-center gap-2">
                    <TruckIcon className="w-4 h-4" />
                    <span>Amazon Shipping Fee (15%)</span>
                    <span className="ml-auto font-semibold">
                        {currency} {shippingFee.toFixed(2)}
                    </span>
                </div>
                <div className="flex items-center gap-2">
                    <PercentIcon className="w-4 h-4" />
                    <span>VAT (19%)</span>
                    <span className="ml-auto font-semibold">
                        {currency} {vat.toFixed(2)}
                    </span>
                </div>
                <Separator />
                <div className="flex items-center gap-2">
                    <DollarSignIcon className="w-4 h-4" />
                    <span>Total Cost</span>
                    <span className="ml-auto font-semibold">
                        {currency} {totalCost.toFixed(2)}
                    </span>
                </div>
                <div className="flex items-center gap-2">
                    <DollarSignIcon className={`w-4 h-4 ${profit <= 0 ? 'text-red-500' : 'text-green-500'}`} />
                    <span className={`${profit <= 0 ? 'text-red-500' : 'text-green-500'}`}>Profit</span>
                    <span className={`ml-auto font-semibold ${profit <= 0 ? 'text-red-500' : 'text-green-500'}`}>
                        {currency} {profit.toFixed(2)}
                    </span>
                </div>
                <div className="flex items-center gap-2">
                    <PercentIcon className={`w-4 h-4 ${profit <= 0 ? 'text-red-500' : 'text-green-500'}`} />
                    <span className={`${profit <= 0 ? 'text-red-500' : 'text-green-500'}`}>Profit Margin</span>
                    <span className={`ml-auto font-semibold ${profit <= 0 ? 'text-red-500' : 'text-green-500'}`}>{profitMargin.toFixed(2)}%</span>
                </div>
            </CardContent>
        </Card>
    )
}

function DollarSignIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="12" x2="12" y1="2" y2="22" />
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
        </svg>
    )
}


function PackageIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m7.5 4.27 9 5.15" />
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
        </svg>
    )
}


function PercentIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="19" x2="5" y1="5" y2="19" />
            <circle cx="6.5" cy="6.5" r="2.5" />
            <circle cx="17.5" cy="17.5" r="2.5" />
        </svg>
    )
}


function ShoppingBagIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z" />
            <path d="M3 6h18" />
            <path d="M16 10a4 4 0 0 1-8 0" />
        </svg>
    )
}


function TruckIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2" />
            <path d="M15 18H9" />
            <path d="M19 18h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14" />
            <circle cx="17" cy="18" r="2" />
            <circle cx="7" cy="18" r="2" />
        </svg>
    )
}
